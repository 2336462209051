var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "div-table-col-product-pricing" }, [
    _c("p", [_vm._v("Pricing Formula")]),
    _c(
      "div",
      [
        _c(
          "el-form-item",
          {
            staticStyle: { width: "200px" },
            attrs: {
              "min-width": "150px",
              label: "Price Rate Type",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.priceRateType`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c(
              "el-select",
              {
                attrs: {
                  value: _vm.productPrice.priceRateType,
                  disabled:
                    _vm.isAllFieldsDisabled ||
                    _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                },
                on: {
                  input: function ($event) {
                    return _vm.handlePriceInfoChange(
                      $event,
                      "priceRateType",
                      true
                    )
                  },
                },
              },
              _vm._l(_vm.ORDER_PRICE_RATE_TYPE_OPTIONS, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            staticStyle: { width: "150px" },
            attrs: {
              "min-width": "100px",
              label: "Price Metric",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.priceRateTypeMetric`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c(
              "el-select",
              {
                attrs: {
                  value: _vm.productPrice.priceRateTypeMetric,
                  disabled:
                    _vm.isAllFieldsDisabled ||
                    _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                },
                on: {
                  input: function ($event) {
                    return _vm.handlePriceInfoChange(
                      $event,
                      "priceRateTypeMetric"
                    )
                  },
                },
              },
              _vm._l(_vm.ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              "min-width": "300px",
              label: "Price Methodology",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.priceMethodologyId`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c(
              "el-select",
              {
                attrs: {
                  value: _vm.productPrice.priceMethodologyId,
                  disabled:
                    _vm.isAllFieldsDisabled ||
                    _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                },
                on: {
                  input: function ($event) {
                    return _vm.handlePriceInfoChange(
                      $event,
                      "priceMethodologyId"
                    )
                  },
                },
              },
              _vm._l(
                _vm.priceMethodologyList.filter(
                  (d) =>
                    d.bunkerwireType.toUpperCase() ===
                    _vm.scopeRow.priceRateType
                ),
                function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.methodologyName, value: item.id },
                  })
                }
              ),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            staticStyle: { width: "100px" },
            attrs: {
              "min-width": "100px",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.isPriceFactorFirst`,
            },
          },
          [
            _c(
              "el-checkbox",
              {
                staticStyle: { "padding-top": "20px" },
                attrs: {
                  value: _vm.productPrice.isPriceFactorFirst,
                  disabled:
                    _vm.isAllFieldsDisabled ||
                    _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED ||
                    ["BUNKERWIRE", "EX_WHARF"].includes(
                      _vm.productPrice.priceRateType
                    ),
                },
                on: {
                  change: function ($event) {
                    return _vm.handlePriceInfoChange(
                      $event,
                      "isPriceFactorFirst"
                    )
                  },
                },
              },
              [_vm._v("Factor First")]
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { "min-width": "150px", label: "Conversion Rate" } },
          [
            _c("el-input-number", {
              attrs: {
                controls: false,
                precision: 3,
                min: null,
                value: _vm.productPrice.priceConversionRate,
                disabled:
                  _vm.isAllFieldsDisabled ||
                  _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED ||
                  ["BUNKERWIRE", "EX_WHARF"].includes(
                    _vm.productPrice.priceRateType
                  ),
              },
              on: {
                change: function ($event) {
                  return _vm.handlePriceInfoChange(
                    $event,
                    "priceConversionRate"
                  )
                },
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            staticStyle: { width: "450px" },
            attrs: {
              "min-width": "200px",
              label: "Pricing Period",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.pricePeriod`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c(
              "el-select",
              {
                attrs: {
                  value: _vm.productPrice.pricePeriod,
                  disabled:
                    _vm.isAllFieldsDisabled ||
                    _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                },
                on: {
                  input: function ($event) {
                    return _vm.handlePriceInfoChange($event, "pricePeriod")
                  },
                },
              },
              _vm._l(_vm.ORDER_PRICE_PERIOD, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              "min-width": "150px",
              label: "Start Date",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.priceStartDate`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c("el-date-picker", {
              attrs: {
                disabled:
                  _vm.isAllFieldsDisabled ||
                  _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                value: _vm.productPrice.priceStartDate,
                type: "date",
                format: "dd-MM-yyyy",
                "picker-options": _vm.dateStartOptions(),
              },
              on: {
                input: function ($event) {
                  return _vm.handlePriceInfoChange($event, "priceStartDate")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              "min-width": "150px",
              label: "End Date",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.priceEndDate`,
              rules:
                _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED
                  ? _vm.notReqRule
                  : _vm.rulesReq,
            },
          },
          [
            _c("el-date-picker", {
              attrs: {
                disabled:
                  _vm.isAllFieldsDisabled ||
                  _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED ||
                  _vm.isPriceEndDateDisabled(),
                value: _vm.productPrice.priceEndDate,
                type: "date",
                format: "dd-MM-yyyy",
                "picker-options": _vm.dateEndOptions(),
              },
              on: {
                input: function ($event) {
                  return _vm.handlePriceInfoChange($event, "priceEndDate")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            staticClass: "el-form-item-price-premium",
            attrs: {
              "min-width": "300px",
              label: "Premium",
              prop: `${_vm.priceProp}.${_vm.scopeIndex}.pricePremium`,
              rules: _vm.notReqRule,
            },
          },
          [
            _c(
              "div",
              { staticClass: "div-price-premium" },
              [
                _c("el-input-number", {
                  attrs: {
                    controls: false,
                    precision: 3,
                    value: _vm.productPrice.pricePremium,
                    disabled:
                      _vm.isAllFieldsDisabled ||
                      _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handlePriceInfoChange($event, "pricePremium")
                    },
                  },
                }),
                _c(
                  "el-select",
                  {
                    staticClass: "unit-metric",
                    attrs: {
                      value: _vm.productPrice.priceUnitMetric,
                      disabled:
                        _vm.isAllFieldsDisabled ||
                        _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handlePriceInfoChange(
                          $event,
                          "priceUnitMetric"
                        )
                      },
                    },
                  },
                  _vm._l(_vm.UNIT_OPTIONS, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-form-item",
          {
            staticStyle: { width: "300px" },
            attrs: { "min-width": "300px", label: "Pricing Remark" },
          },
          [
            _c("el-input", {
              attrs: {
                value: _vm.productPrice.priceRemark,
                disabled:
                  _vm.isAllFieldsDisabled ||
                  _vm.priceType === _vm.ORDER_PRICE_TYPE.FIXED,
              },
              on: {
                input: function ($event) {
                  return _vm.handlePriceInfoChange($event, "priceRemark", true)
                },
              },
            }),
          ],
          1
        ),
        _vm.parent === "DialogVerifyOrder"
          ? _c(
              "el-form-item",
              { attrs: { "min-width": "300px", label: "Reference Price" } },
              [
                _c("el-input-number", {
                  attrs: {
                    controls: false,
                    precision: 2,
                    value: _vm.productPrice.referencePrice,
                    disabled: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.handleReferencePriceChange($event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }